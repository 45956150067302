.sidebar {
    display: flex;
    width: 200px;
    position: relative;
    z-index: 1;

    ul {
        padding: 15px 0 0 0;
        width: 100%;
        display: block;

        li {

            margin-bottom: 10px;
            list-style: none;
            display: block;

            span {
                display: block;

                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: #323c47;
                text-decoration: none;
                background: #e6f2ff;
                padding: 15px;
                border-radius: 5px;
                border: 1px solid #e6f2ff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;

                img {
                    margin-right: 5px;
                    vertical-align: text-bottom;
                    max-width: 20px;
                }

                &.active {
                    width: calc(100% + 12px);
                    padding-right: 27px;
                    background: #ffffff;
                    box-shadow: -4px 0px 5px 0 rgba(0, 0, 0, 0.1);
                    border-right: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }

            a {
                display: block;
                cursor: pointer;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: #323c47;
                text-decoration: none;
                background: #e6f2ff;
                padding: 15px;
                border-radius: 5px;
                border: 1px solid #e6f2ff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                img {
                    margin-right: 5px;
                    vertical-align: text-bottom;
                    max-width: 20px;
                }

                &.active {
                    width: calc(100% + 12px);
                    padding-right: 27px;
                    background: #ffffff;
                    box-shadow: -4px 0px 5px 0 rgba(0, 0, 0, 0.1);
                    border-right: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }
    }
}