.matrix-view-content {
    display: block;
    width: 100%;

    .tabs {
        display: block;
        width: 100%;
        padding: 10px 0;

        .tab {
            list-style: none;
            width: 100%;
            display: block;
            padding: 0 15px;
            margin: 0;

            li {
                display: inline-block;
                vertical-align: top;
                padding: 10px 20px;

                cursor: pointer;
                position: relative;

                span {
                    display: block;
                    font-size: 14px;
                    color: #3399ff;
                    font-weight: 500;
                    line-height: 22px;
                    position: relative;
                    z-index: 1;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    bottom: -12%;
                    height: 100%;
                    width: 100%;
                    content: "";
                }

                &.active {
                    box-shadow: -2px -2px 6px 2px rgba(0, 0, 0, 0.1);

                    &::after {
                        background-color: #ffffff;
                    }
                }
            }
        }

        .tabs-content {
            display: block;
            width: 100%;
            padding: 20px 30px;
            box-shadow: -2px 1px 8px 3px rgba(0, 0, 0, 0.1);

            .content {
                display: block;
                width: 100%;

                span {
                    font-weight: 400;
                }
                .design-specs-img{
                    display: block;
                    width: 100%;
                    padding: 40px 50px;
                }
            }
        }
    }
}