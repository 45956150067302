.receive-rfq-content {
    display: block;
    width: 100%;
    padding-left: 50px;
    position: relative;

    .line {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;



        .line1 {
            margin-right: auto;
            height: 120px;
            width: 0;
            margin-top: 120px;
            border-left: 2px dashed #add6ff;
            position: relative;

            &::after {
                border-top: 2px dashed #add6ff;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 45px;
                height: 0;
                content: "";
            }
        }

        .line2 {
            height: 0px;
            width: 100%;
            border-top: 2px dashed #add6ff;
        }

        .line3 {
            margin-left: auto;
            height: 120px;
            width: 0;
            margin-top: -120px;
            border-right: 2px dashed #add6ff;
            position: relative;

            &::after {
                border-top: 2px dashed #add6ff;
                position: absolute;
                right: 0;
                top: 0;
                width: 45px;
                height: 0;
                content: "";
            }
        }
    }

    .row {
        position: relative;
        z-index: 1;
    }

    .col-12 {
        position: relative;
        z-index: 1;

        &:nth-of-type(3n) {
            .receive-rfq-box {
                &::after {
                    display: none;
                }
            }
        }
    }

    .receive-rfq-box {
        position: relative;
        margin: 40px 50px 40px 0;
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        border-top: 5px solid #79bcff;

        .receive-number {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.1);
            font-size: 72px;
            font-weight: 600;
        }

        .heading {
            padding: 10px;
            text-align: center;
            background: #c1e0ff;
            position: relative;
            z-index: 1;

            h3 {
                color: 525252;
            }
        }

        ul {
            padding: 20px;
            margin: 0;
            position: relative;
            z-index: 1;

            li {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                list-style: none;
                margin-bottom: 15px;
                color: #3399ff;
                padding-left: 20px;
                background-repeat: no-repeat;
                background-position: 0 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.active {
                    background-image: url(../images/check-icon1.png);
                }

                &.inprosseg {
                    background-image: url(../images/check-icon2.png);
                }
            }
        }

        &::after {
            content: "";
            background-image: url(../images/right-arrow.png);
            background-repeat: no-repeat;
            position: absolute;
            right: -70px;
            top: 50%;
            width: 60px;
            height: 21px;
        }

        &.active {
            border-top: 5px solid #398038;
        }

        &.inprosseg {
            border-top: 5px solid #ff5400;
        }

    }

}