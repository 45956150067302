.header {
    margin-bottom: 15px;

    .head-left {
        h5 {
            color: #3b4859;
        }
    }

    .head-right {
        text-align: right;

        .filter-icon {
            margin-right: 15px;

            img {
                max-width: 30px;
            }
        }

        .search-filter {
            width: 250px;
            margin-right: 15px;

            .form-select {
                border-radius: 30px;
                border-color: #a9b9c6;
                color: #a9b9c6;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .searchbar {
            width: 250px;

            .form-control {
                border-radius: 30px;
                border-color: #a9b9c6;
                color: #a9b9c6;

                &:focus {
                    box-shadow: none;
                }
            }
        }


    }
}

@media only screen and (min-width: 768px)and(max-width: 992px) {
    .header {
        .head-left {
            h5{
                font-size: 1.0rem;
            }
        }
    }
}