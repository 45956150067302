.workflow-content {
    .workflow-stage {
        display: flex;
        width: 100%;

        ul {
            padding: 70px 0;
            display: flex;
            width: 100%;
            list-style: none;


            li {

                position: relative;
                width: 16.6666%;
                text-align: center;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    top: 15px;
                    left: 0;
                    background-color: #9ec7fc;
                    width: 100%;
                    height: 2px;
                }

                span {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background-color: #b1c9f8;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 14px;
                    line-height: 32px;
                    color: #2662f0;
                    position: relative;
                    z-index: 1;
                    cursor: pointer;
                }

                &.completed {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 15px;
                        left: 0;
                        background-color: #398038;
                        width: 100%;
                        height: 2px;
                    }

                    span {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        background-color: #398038;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 14px;
                        line-height: 32px;
                        color: #fff;
                        position: relative;
                        z-index: 1;
                    }
                }


                &.laudantium {
                    input {
                        position: absolute;
                        top: -30px;
                        left: 0;
                        width: 100%;
                        cursor: pointer;
                    }
                }

                &:nth-child(1),
                &:nth-child(3),
                &:nth-child(5) {
                    .form-control {
                        position: absolute;
                        top: -60px;
                        width: 100%;
                        border: 1px solid #399ef7;
                        background-color: #ffffff;
                        text-align: center;
                        padding: 8px;
                        border-radius: 3px;
                        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: #e6f2ff;

                            &::after {
                                border-top-color: #e6f2ff;
                            }
                        }

                        input {
                            width: 100%;
                            text-align: center;
                            border: none;
                            color: #777777;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 20px;
                            background-color: transparent;
                            cursor: pointer;

                            &:focus-visible {
                                outline: none;
                            }
                        }

                        &::before,
                        &::after {
                            top: 100%;
                            left: 50%;
                            border: solid transparent;
                            content: "";
                            height: 0;
                            width: 0;
                            position: absolute;
                            cursor: pointer;
                        }

                        &::before {
                            border-color: rgba(194, 225, 245, 0);
                            border-top-color: #399ef7;
                            border-width: 14px;
                            margin-left: -14px;
                        }

                        &::after {
                            border-color: rgba(136, 183, 213, 0);
                            border-top-color: #fff;
                            border-width: 12px;
                            margin-left: -12px;
                        }
                    }
                }

                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6) {
                    .form-control {
                        position: absolute;
                        bottom: -60px;
                        width: 100%;
                        border: 1px solid #399ef7;
                        background-color: #ffffff;
                        text-align: center;
                        padding: 8px;
                        border-radius: 3px;
                        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: #e6f2ff;

                            &::after {
                                border-bottom-color: #e6f2ff;
                            }
                        }

                        input {
                            width: 100%;
                            text-align: center;
                            border: none;
                            color: #777777;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 20px;
                            background-color: transparent;
                            cursor: pointer;

                            &:focus-visible {
                                outline: none;
                            }
                        }

                        &::before,
                        &::after {
                            bottom: 100%;
                            left: 50%;
                            border: solid transparent;
                            content: "";
                            height: 0;
                            width: 0;
                            position: absolute;
                            cursor: pointer;
                        }

                        &::before {
                            border-color: rgba(194, 225, 245, 0);
                            border-bottom-color: #399ef7;
                            border-width: 14px;
                            margin-left: -14px;
                        }

                        &::after {
                            border-color: rgba(136, 183, 213, 0);
                            border-bottom-color: #fff;
                            border-width: 12px;
                            margin-left: -12px;
                        }
                    }
                }

            }

            .inprosseg {
                &::before {
                    content: "";
                    position: absolute;
                    top: 15px;
                    left: 0;
                    background-color: #ff5400;
                    width: 100%;
                    height: 2px;
                }

                span {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background-color: #ff5400;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 14px;
                    line-height: 32px;
                    color: #fff;
                    position: relative;
                    z-index: 1;
                }
            }
        }

        .btn-edit {
            height: 40px;
            white-space: nowrap;
        }

    }

    .workflow-data {
        border-top: 1px dotted #abd5f9;

        .api-code {
            border: 1px solid #707070;
            margin-top: 15px;
            border-radius: 3px;

            .heading {
                background: #b1c9f8;
                padding: 10px 15px;

                h5 {
                    color: #3b4859;
                }
            }

            .api-content {
                padding: 30px 15px;

                label {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #3a4758;
                    margin-right: 15px;
                }

                input {
                    width: 60%;
                    margin-right: 15px;
                    border: 1px solid#e0e0e0;
                    border-radius: 3px;
                    padding: 5px 10px;

                    &:focus-visible {
                        outline: none;
                    }
                }

                .code-update-btn {
                    padding: 5px 25px;
                    vertical-align: initial;
                }
            }
        }
    }

    .workflow-requirement {
        border: 1px solid #707070;
        margin-top: 15px;
        border-radius: 3px;
        padding-bottom: 10px;

        .heading {
            background: #b1c9f8;
            padding: 10px 15px;

            h5 {
                margin-bottom: 0;
                color: #3b4859;
            }
        }

        .requirement-data {
            padding: 5px 15px 0 15px;

            input {
                margin-right: 10px;
                vertical-align: middle;
            }

            span {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: #3b4859;
                vertical-align: baseline;
            }
        }
    }

    .workflow-buttons {
        margin-top: 20px;

        .btn {
            margin-left: 15px;
        }
    }
}

@media only screen and (min-width: 768px)and(max-width: 992px) {
    .workflow-content {
        .workflow-data {
            .api-code {
                .api-content {
                    .code-update-btn {
                        margin-left: 103px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}