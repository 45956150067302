.dashboard-content {
    .basic-details {
        h5 {
            color: #3b4859;
        }

        .input-group {
            display: block;
            margin-bottom: 20px;
            width: 500px;

            label {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: #414d55;
                margin-bottom: 8px;
                display: block;
            }

            .form-control {
                width: 100%;
                border-color: #8392a7;
                color: #757f8a;
                font-style: italic;
                border-radius: 0.25rem !important;

                &:focus {
                    box-shadow: none;
                }
            }

            .assign {
                width: 100%;
                height: 40px;
                border-color: #8392a7;
                color: #757f8a;
                border-radius: 0.25rem !important;

                &:focus-visible {
                    outline: none;
                }

            }
        }
    }

    .workflow-detail {
        h5 {
            color: #3b4859;
            margin-bottom: 20px;
        }

        .add-workflow-btn {
            margin-top: 15px;
            margin-bottom: 20px;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .add-workflow-list {
        border-left: 2px solid #9ec7fc;
        height: 100%;
        padding-left: 20px;
        position: relative;

        span {
            display: inline-block;
            width: 32px;
            height: 32px;
            background-color: #b1c9f8;
            border-radius: 50%;
            text-align: center;
            font-size: 14px;
            line-height: 32px;
            color: #2662f0;
            position: absolute;
            left: -17px;
            top: 5px;
            z-index: 1;
        }

        .workflow-type {
            display: block;
            margin-bottom: 20px;
            margin-left: 10px;
            width: 520px;

            .form-control {
                display: inline-block;
                width: 75%;
                border: 1px solid #399ef7;
                background-color: #ffffff;
                text-align: center;
                padding: 8px;
                border-radius: 3px;
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);

                &:focus {
                    box-shadow: none;
                }
            }

            .close {
                display: inline-block;
                width: 25%;
                text-align: right;

                button {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    background-color: red;
                    color: #ffffff;
                    border-radius: 50%;
                    border: none;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 600;
                    text-align: center;
                }
            }

            &::before,
            &::after {
                right: calc(100% - 31px);
                top: 7%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &::before {
                border-color: rgba(194, 225, 245, 0);
                border-right-color: #399ef7;
                border-width: 13px;
                margin-top: -13px;
            }

            &::after {
                border-color: rgba(136, 183, 213, 0);
                border-right-color: #fff;
                border-width: 11px;
                margin-top: -11px;
            }
        }

        .description {
            padding-bottom: 25px;
            margin-left: 25px;
            width: 500px;

            label {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: #414d55;
                margin-bottom: 8px;
                display: block;
            }

            .form-control {
                width: 100%;
                border-color: #8392a7;
                color: #757f8a;
                font-style: italic;
                border-radius: 0.25rem !important;

                &:focus {
                    box-shadow: none;
                }
            }

            .assign {
                width: 100%;
                height: 40px;
                border-color: #8392a7;
                color: #757f8a;
                border-radius: 0.25rem !important;

                &:focus-visible {
                    outline: none;
                }

            }
        }
    }

    .basic-details-btn {
        text-align: right;
        margin-bottom: 15px;

        .btn-primary {
            padding: 0.375rem 2rem;
        }
    }
}

@media only screen and (min-width: 768px)and(max-width: 992px) {
    .dashboard-content {
        .basic-details {
            .input-group {
                width: 350px;
            }
        }

        .add-workflow-list {
            .workflow-type {
                width: 370px;
            }

            .description {
                width: 350px;
            }
        }
    }
}