*,
*::after,
*::before {
    box-sizing: border-box;
}

img{
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    background-color: #f1f1f1;
}

.app-content {
    display: block;
    width: 100%;
    height: 100%;
}

.main-page {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.page-content-view {
    display: block;
    width: 100%;
    background: #fff;
    padding: 10px 20px 10px 10px;
    border-radius: 10px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    min-height: 680px;

    .page-content {
        display: flex;
        width: 100%;

        .right-content {
            width: calc(100% - 210px);
            background: #fff;
            border-radius: 10px;
            border: 1px solid #d7e6f6;
            padding: 15px 25px;
            margin-left: 10px;
            min-height: 600px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        }
    }
}